import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import { relatedShowcase } from "~/queries/pageQuery"; // eslint-disable-line
import Layout from "../components/layout";

class PagesPost extends Component {
	render() {
		const {
			data: {
				recraft: { post }
			}
		} = this.props;

		return (
			<Layout location={this.props.location}>
				<div className="container">
					<div className="reading w-full md:w-2/3 mx-auto my-16">
						<h1 className="text-3xl uppercase text-center font-din font-black mb-12">
							{post.title}
						</h1>
						<div className="my-8">
							<Link to="/articles">Back</Link>
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}

export default PagesPost;

export const pageQuery = graphql`
	query PagesPostByUri($uri: String!) {
		recraft {
			post: entry(uri: $uri) {
				...relatedPages
			}
		}
	}
`;
