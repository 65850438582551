/* global tw */
import React from "react";
// import { StaticQuery, Link, graphql } from "gatsby";
// import Header from "../components/Header/Header.js";
// import Footer from "../components/Footer";
import PropTypes from "prop-types";
import styled from "styled-components";
// import SiteBackground from "./SiteBackground.js";

const AdjContainer = styled.div`
	${tw`md:pl-24 mx-auto min-h-screen w-full`};
`;
// md:-ml-24

class Layout extends React.Component {
	render() {
		const {
			location,
			coloring,
			headerPosition,
			headerColor,
			children
		} = this.props;
		return (
			<div className={coloring + " w-full flex flex-col"}>
				<AdjContainer>{children}</AdjContainer>
			</div>
		);
	}
}

Layout.propTypes = {
	coloring: PropTypes.string
};

export default Layout;

// bg-pink
